// Remove font smoothing.
.panels-ipe-control {
  -webkit-font-smoothing: auto;
}

// Use Bootstrap 4's native font stack.
.div.panels-ipe-placeholder,
div.panels-ipe-handlebar-wrapper li a,
div.panels-ipe-dragtitle span,
div.panels-ipe-newblock a,
span.panels-ipe-draghandle-icon,
div.panels-ipe-pseudobutton-container,
div.panels-ipe-control .form-submit {
  font-family: @font-family-native-font-stack;
}

// Remove bottom margin below IPE regions.
.panels-ipe-editing .panels-ipe-region {
  margin-bottom: 0;
}

// Panels IPE placeholders.
div.panels-ipe-placeholder {
  border: none;
  background-color: #d8711d;
  margin-bottom: 5px;

  // Hide region titles.
  .panels-ipe-placeholder-content {
    display: none;
  }

  // Since region titles are now hidden, give it a height.
  height: 50px;
}

// Hide Panels IPE pane titles.
.panels-ipe-dragbar-admin-title {
  display: none;
}

// Hide ajax spinners which appear after clicking the Panels IPE buttons.
ul.panels-ipe-linkbar a .ajax-progress {
  display: none;
}

// Throbber which appears after clicking "Edit content" or "Change layout".
.ipe-throbber {
  background-image: none;
  color: white;
  font-size: 14px;
  border-radius: 50%;
  padding: 0;
  text-align: center;
  line-height: 30px;
  height: 28px;
  width: 28px;
}

// Remove background images from toolbar buttons (we're adding icons in
// steindom_theme_preprocess_panels_ipe_toolbar), use consistent background
// gradients, and make the Save icon stand out with white text.
div.panels-ipe-pseudobutton-container a.panels-ipe-pseudobutton {
  padding-left: .8em;
  background-image: none;
}
div.panels-ipe-control .form-submit {
  padding-left: .8em;
}
div.panels-ipe-pseudobutton-container,
div.panels-ipe-control .form-submit {
  background-image: linear-gradient(to top, #383838 0%, #666666 100%);
}
div.panels-ipe-pseudobutton-container:hover,
div.panels-ipe-control .form-submit:hover,
div.panels-ipe-pseudobutton-container:focus,
div.panels-ipe-control .form-submit:focus {
  background-image: linear-gradient(to top, #3D3D3D 0%, #999999 100%);
}
div.panels-ipe-control .panels-ipe-save {
  color: white;
}

// Style the IPE drag-and-drop UI.
div.panels-ipe-newblock,
div.panels-ipe-handlebar-wrapper li a,
div.panels-ipe-dragtitle span,
div.panels-ipe-newblock a,
span.panels-ipe-draghandle-icon {
  box-shadow: none;
}
div.panels-ipe-draghandle {
  padding: 0;
  background: transparent;
}
div.panels-ipe-handlebar-wrapper {
  position: absolute;
  top: 5px;
  right: 5px;
  border: none;
  display: none !important;
  z-index: 1000;
}
.panels-ipe-editing {
  .panels-ipe-sort-container .ui-sortable-placeholder {
    border: 1px dashed #555;
    background-color: lightyellow;
    margin: 1em 0;
    border-radius: 0;
  }
  div.panels-ipe-portlet-wrapper {
    margin-top: 0;
    border: none;
    position: relative;
    min-height: 45px;
    &:hover {
      border: 0;
      outline: 1px dashed #ccc;
      div.panels-ipe-handlebar-wrapper {
        display: block !important;
      }
    }
  }
  .panels-ipe-portlet-content {
    margin: 0;

    // Allows breakout containers to retain full-width.
    // https://trello.com/c/bZwqSRQp
    overflow: visible;
  }
}

// Hide Contextual Links triggers when IPE is active.
body.panels-ipe-editing .contextual-links-trigger {
  display: none;
}

// Helper classes to toggle visibility of stuff based on whether or not IPE is
// active. Similar to Bootstrap's responsive utility classes.
.visible-ipe-block, .visible-ipe-inline-block, .visible-ipe-inline {
  display: none;
}
body.panels-ipe-editing {
  .hidden-ipe {
    display: none;
  }
  .visible-ipe-block {
    display: block;
  }
  .visible-ipe-inline-block {
    display: inline-block;
  }
  .visible-ipe-inline {
    display: inline;
  }
}

// Show hidden visibility panes when IPE is active.
.panels-ipe-portlet-content {
  > .hidden-xs,
  > .hidden-sm,
  > .hidden-md,
  > .hidden-lg {
    display: block !important;
  }
}
