// Cart form.
#uc-cart-view-form {
  img {
    float: none;
    padding-right: 0;
  }
  td.desc {
    vertical-align: middle;
  }
  .product-description {
    .list-unstyled();
  }
  td.remove .btn-link {
    padding-left: 0;
    padding-right: 0;
  }

  // Responsive cart.
  @media @mobile-only {
    .table-responsive {
      border: none;
    }
    tr {
      background-color: transparent;
      border-bottom: 1px solid #ddd;
    }
    th {
      display: none;
    }
    td {
      display: inline-block;
      padding-left: 0;
      padding-right: 0;
    }
    td.qty {
      width: 30%;
    }
    td.price {
      width: 40%;
      text-align: center;
    }
    td.remove {
      width: 30%;
      text-align: center;
    }
    td.subtotal {
      display: block;
      text-align: center;
      padding: 20px 0;
    }
    tr:last-child td:last-child {
      display: none;
    }
    .entity-uc-cart-item {
      display: none;
    }
    .form-actions {
      margin-bottom: 2em;
      a, button {
        display: block;
        width: 100%;
        margin: 10px 0 !important;
        text-align: center;
        float: none !important;
      }
    }
  }
}

// Coupon form.
#uc-cart-pane-coupon {
  padding-bottom: 1em;
}

// Shipping quotes.
#uc-cart-pane-quotes .form-submit {
  margin: 0;
}

// Checkout form.
#uc-cart-checkout-form {

  // Add spacing between coupon sections.
  #uc-coupon-active-coupons, #coupon-messages {
    margin-top: 1em;
  }

  // Add spacing between payment options.
  .form-item-panes-payment-payment-method {
    margin-bottom: 1em;
  }

}

// Fix address fields on mobile.
@media @mobile-only {
  .uc-store-address-field .form-item label {
    float: none;
    text-align: left;
    width: auto;
  }
  .form-item-panes-delivery-delivery-street2,
  .form-item-panes-billing-billing-street2 {
    label {
      display: none;
    }
  }
}
