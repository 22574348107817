// Display file description fields inline with media image previews, and fix
// leading margin before selecting a file.
.media-widget {
  .preview {
    vertical-align: top;
    margin-right: 0;
    .media-item {
      padding: 0;
      border: none;
      .media-thumbnail {
        line-height: 0;
        max-width: 100px;
      }
      .label-wrapper {
        position: relative;
        line-height: 1em;
        label {
          padding: 3px 5px 5px;
          margin: 0;
          display: block;
        }
      }
    }
  }
  .remove {
    margin-left: 10px;
  }
  .form-type-textfield {
    display: inline-block;
    margin-left: 10px;
  }
}

// Align padding in UI widget with tabs panel.
#media-browser-tabset #branding {
  padding: 10px 20px 0;
}

// Don't float the Media tabs wrapper.
#media-tabs-wrapper {
  float: none;
}
