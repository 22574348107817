// This file contains helper classes that can be added to HTML for quick and
// easy styling.
// ----------------------------------------------------------------------------

// Flex and Bootstrap 4 stuff *************************************************

.row-flex {
  display: flex;
  flex-wrap: wrap;
  &:before, &:after {
    display: none;
  }
}

@media @tablet {
  .row-sm-flex {
    .row-flex();
  }
}

@media @desktop {
  .row-md-flex {
    .row-flex();
  }
}

@media @large {
  .row-lg-flex {
    .row-flex();
  }
}

.align-items-center {
  align-items: center !important;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
  > [class*="col-"] {
    padding-right: 0;
    padding-left: 0;
  }
}

// Forms **********************************************************************

// Add to form fields to use native field width.
.form-control-auto {
  width: auto;
  max-width: 100%;
}

// Tables *********************************************************************

.table-auto {
  width: auto;
}

// Images *********************************************************************

.img-right {
  float: right;
  margin: 0 0 15px 25px;
}

.img-left {
  float: left;
  margin: 0 25px 15px 0;
}

.img-center {
  text-align: center;
  margin-bottom: 15px;
}

.img-captioned {
  background: white;
  padding: 7px;
  box-shadow: 0 0 3px 0 fade(black, 50%);

  figcaption {
    &:extend(.text-caption all);
    text-align: center;
    font-family: @font-family-sans-serif;
    font-weight: bold;

    margin-top: .5em;
    &:empty {
      margin-top: 0;
    }
  }

  .img-center & {
    margin-right: auto;
    margin-left: auto;
  }
}

img.img-bordered, .img-bordered img {
  &:extend(.img-thumbnail all);
}

.img-shadowed {
  box-shadow: 2px 2px 10px fade(black, 70%);
}

// Lists **********************************************************************

// Navigation style lists.
.list-navigation {
  padding-left: 0;
  .pane-style-style & {
    padding-left: 1em;
  }

  list-style: none;
  font-size: @list-navigation-font-size;
  line-height: 1.2em;

  // Indent sub lists.
  ul {
    padding-left: 1em;
  }

  // Show right chevron to left of active link.
  li {
    position: relative;

    &.active:before {
      font-family: 'Glyphicons Halflings';
      content: '\e080';
      position: absolute;
      left: -3.5ch;
      color: @list-navigation-arrow-color;
    }
  }

  // Full-width links.
  a {
    display: block;
    margin-bottom: .5em;

    // Make active link not look like a link.
    &.active {
      color: inherit;
      &:hover, &:focus {
        text-decoration: none;
        cursor: default;
      }
    }
  }
}

// Text ***********************************************************************

// Between normal text and 'lead'.
.bigger {
  margin-bottom: @line-height-computed;
  font-size: floor((@font-size-base * 1.1));

  @media (min-width: @screen-sm-min) {
    font-size: (@font-size-base * 1.25);
  }
}

// Text caption.
.text-caption {
  color: @text-muted;
  font-size: 90%;
}
