// Creates arrows on Submenu widgets.
.submenu-arrows(@font-family: 'Glyphicons Halflings', @collapsed: '\e080', @expanded: '\e114') {
  li.submenu-parent:before {
    font-family: @font-family;
    float: left;
    font-size: 0.8em;
    margin-top: 0.4em;
    margin-left: -1em;
  }
  li.submenu-collapsed:before {
    content: @collapsed;
  }
  li.submenu-expanded:before {
    content: @expanded;
  }
}

// Adds smaller font sizes as Submenu drills down
.submenu-font-size-tier() {
  ul ul a {
    font-size: 0.9em;
  }
  ul ul ul a {
    font-size: 0.8em;
  }
  ul ul li.submenu-collapsed:before,
  ul ul li.submenu-expanded:before {
    font-size: 0.7em;
    margin-top: 0.5em;
  }
  ul ul ul li.submenu-collapsed:before,
  ul ul ul li.submenu-expanded:before {
    font-size: 0.6em;
    margin-top: 0.6em;
  }
}

// Adds nested hover colors for Submenu widgets.
.submenu-hover-colors(@bg-color: rgba(0, 0, 0, 0.7), @text-color: #FFF) {
  .pane-content a:hover {
    background-color: @bg-color;
    column-rule: @text-color;
  }
}
