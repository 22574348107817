// This file contains element defaults, fixes to make Drupal and Bootstrap play
// well together, etc.
// ----------------------------------------------------------------------------

// Body text weight and font smoothing.
body {
  font-weight: @font-weight;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// Image opacity on hover.
a img {
  .opacity-hover();
}

// Make all responsive images inline-block (so they can be centered), with an
// auto height (so they don't appear stretched thanks to inline styles).
.img-responsive {
  display: inline-block !important;
  height: auto !important;
}

// Fix images in tables.
table .img-responsive {
  vertical-align: initial;
  max-width: initial;
}

// Fix tablesort images.
table th.active .img-responsive {
  margin-left: 4px;
}

// Fixes issues with autocomplete fields in a responsive table. Just set the
// overflow for mobile only.
.table-responsive {
  overflow-x: initial;
  @media @mobile-only {
    overflow-x: auto;
  }
}

// Remove default top border on table cells.
.table {
  > thead, > tbody, > tfoot {
    > tr {
      > th, > td {
        border-top: none;
      }
    }
  }
}

// Remove left padding from first table cell, unless it's a bordered or striped
// table.
.table:not(.table-bordered):not(.table-striped) {
  th:first-child, td:first-child {
    padding-left: 0;
  }
}

// Center table captions.
caption {
  text-align: center;
}

// Give some top margin to form action buttons (unless within a modal).
.form-actions {
  margin-top: 1em;
  .modal-footer & {
    margin-top: 0;
  }
}

// Containers within containers.
.container .container,
.container .container-fluid,
.container-fluid .container,
.container-fluid .container-fluid {
  width: auto;
  margin-left: -15px;
  margin-right: -15px;
}

// Breakout containers. Also see body declaration below.
// https://codepen.io/tigt/post/bust-elements-out-of-containers-with-one-line-of-css
// https://css-tricks.com/full-width-containers-limited-width-parents/
.breakout {
  margin: 0 calc(50% - 50vw);
  > .container {
    .container-fixed();
    @media (min-width: @screen-sm-min) {
      width: @container-sm;
    }
    @media (min-width: @screen-md-min) {
      width: @container-md;
    }
    @media (min-width: @screen-lg-min) {
      width: @container-lg;
    }
  }

  // There's an issue with "background-size: cover" on iOS devices, so for
  // mobile only, let's set this to "scroll", which changes the behavior but
  // prevents weird rendering issues.
  // https://stackoverflow.com/q/18429620
  // https://trello.com/c/KzL9twC2
  @media @mobile-only {
    background-attachment: scroll !important;
  }
}

// Needed to fix horizontal scrollbars when page width is 100vw, as in the case
// of .breakout--container above.
// https://codepen.io/tigt/post/bust-elements-out-of-containers-with-one-line-of-css
body {
  overflow-x: hidden;

  // Restore overflow to allow dragging outside of the page in Panels IPE.
  &.panels-ipe-editing {
    overflow-x: visible;
  }
}

// Add margin beneath DD tags.
dd {
  margin-bottom: 1em;
}

// Make collapsible fieldset links more obvious.
fieldset .panel-heading a.panel-title {
  text-decoration: underline;
}

// Hide the "hide/show descriptions" link on the admin index pages.
.compact-link {
  display: none;
}

// Add some spacing between pane heading glyphicons and the text.
.pane-title .glyphicon {
  margin-right: 10px;
}

// Use smaller left padding for nested lists.
ul ul, ul ol, ol ul, ol ol,
.list-unstyled ul, .list-unstyled ol {
  padding-left: 1em;
}

// Prevent buttons with long text from breaking layouts. Also give transition
// from Bootstrap 4.
.btn {
  white-space: normal;
  transition: all 0.15s ease-in-out;
}

// Non-.btn buttons as regular default buttons.
a.button {
  &:extend(.btn all);
  &:extend(.btn-default all);
}

// With <p> tags having the Bootstrap bg classes, add padding.
p.bg-primary, p.bg-success, p.bg-info, p.bg-warning, p.bg-danger {
  padding: 15px;
}

// Blockquotes.
blockquote {
  margin: @line-height-computed 0;
  padding: 0 15px 0 45px;
  border-left: none;
  font-family: @blockquote-font-family;
  position: relative;

  &:after {
    font-family: @blockquote-quote-font-family;
    content: '\0201C';
    font-size: 75px;
    line-height: 1em;
    position: absolute;
    top: @blockquote-quote-offset;
    left: 0;
  }

  footer {
    font-family: @blockquote-footer-font-family;
    margin-top: 10px;
  }
}

// Reverse blockquotes.
.blockquote-reverse, blockquote.pull-right {
  padding: 0 45px 0 15px;
  border-right: none;
  text-align: right;

  &:after {
    content: '\201D';
    right: 0;
  }
}

// On dark backgrounds, make the "Skip to main content" link white.
#skip-link a when (luma(@body-bg) < 50%) {
  color: white;
}

// Reset Bootstrap page header component.
.page-header {
  padding-bottom: 0;
  margin: 0;
  border-bottom: none;
}

// Target WYSIWYG content after it's rendered through input filter.
.filter-wysiwyg {
  // Remove top padding on first heading element.
  h1, h2, h3, h4, h5, h6 {
    &:first-child {
      margin-top: 0;
    }
  }

  // Fix list positioning next to floated content.
  // https://basecamp.com/2784215/projects/7844084/todos/231393557
  ul, ol {
    overflow: auto;
  }

  // Add vertical separation for ordered list items.
  ol li:not(:last-child) {
    margin-bottom: 0.5em;
  }

  // Fix long links on mobile.
  @media @mobile-only {
    a:not(.btn) {
      word-wrap: break-word;
    }
  }

  // Make these images responsive.
  img {
    .img-responsive();
  }

  // Center floated images and figures on mobile.
  @media @mobile-only {
    .img-left, .img-right {
      float: none;
      margin: 15px auto !important;
      text-align: center;
    }
    img.img-left, img.img-right {
      display: block !important;
    }
  }

  // Add margin to floated tables, and center it on mobile.
  table[align="right"] {
    margin: 0 0 15px 15px;
  }
  table[align="left"] {
    margin: 0 15px 15px 0;
  }
  @media @mobile-only {
    table[align="right"], table[align="left"] {
      margin: 0 auto;
      &.table-auto {
        width: 100%;
      }
    }
  }
}

// Make panel descriptions a normal font size.
.panel-body > .help-block {
  font-size: 1em;
}

// Tighten up password confirm fields.
.form-type-password-confirm > .help-block {
  margin-top: 0;
}

// Responsive audio element.
audio {
  max-width: 100%;
}

// Multiple value form items.
// See steindom_theme_field_multiple_value_form().
.panel.field-multiple-panel {
  // Place "Show row weights" toggle link in the panel header.
  position: relative;
  .tabledrag-toggle-weight-wrapper {
    position: absolute;
    top: .75em;
    right: 10px;
  }

  // Messages.
  .alert {
    margin: .5em;
  }

  // Add borders between each table row.
  tr:not(:last-child) {
    border-bottom: 1px dashed @panel-default-border;
  }
}

// Add spacing between pager controls.
.pager-current {
  margin-left: 10px;
  margin-right: 10px;
}

// Just in case these classes are added to a parent element (such as a figure).
.img-rounded img {
  .img-rounded();
}
.img-circle img {
  .img-circle();
}

// Match non-linked fieldset panel titles with linked ones.
// See overrides.less
fieldset .panel-heading span.panel-title {
  display: block;
  margin: -10px -15px;
  padding: 10px 15px;
}

// Full-height vertical tabs.
.vertical-tabs {
  display: flex;
  .vertical-tabs-panes {
    width: 100%;
  }
}

// Ensure input group form controls within vertical tabs default to full width.
// Fixes stuff in uc_store.css, but is not specific to that use case.
.vertical-tabs .input-group .form-control {
  width: 100%;
}

// When text color is light, use Bootstrap default for contextual backgrounds.
.bg-primary when (luma(@brand-primary) > 50%) and (luma(@text-color) > 50%) {
  color: #333;
}
.bg-success, .bg-info, .bg-warning, .bg-danger {
  & when (luma(@text-color) > 50%) {
    color: #333;
  }
}

// Form wrappers are basically form groups.
.form-wrapper {
  &:extend(.form-group all);
}
