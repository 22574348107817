// Generic title mixin.
.pane-style-title-generic() {
  margin: 0 0 .5em;
  font-family: @pane-style-title-font-family;
  font-size: @pane-style-title-font-size;
  font-weight: @pane-style-title-font-weight;

  // Bottom border when inside style.
  & when (@pane-style-title-bordered) and (@pane-style-title-position = inside) {
    padding-bottom: .5em;
    border-bottom: 1px solid @text-color;
  }

  // Alignment.
  & when not (@pane-style-title-align = false) {
    text-align: @pane-style-title-align;
  }
}

// Generic content mixin.
.pane-style-content-generic() {
  padding: @pane-style-padding;
  font-size: @pane-style-font-size;

  // Border radius.
  & when not (@pane-style-border-radius = false) {
    border-radius: @pane-style-border-radius;
  }

  // Bordered.
  & when not (@pane-style-border = false) {
    border: @pane-style-border;
  }

  // Add box shadow.
  & when not (@pane-style-box-shadow = false) {
    box-shadow: @pane-style-box-shadow;
  }
}

// Color variant mixin.
.pane-style-variant(@bg-color) {
  // Set background color and text color.
  & when (@pane-style-title-position = inside) {
    background-color: @bg-color;
    &, > .pane-title {
      color: @text-color;
    }
  }
  > .pane-content when (@pane-style-title-position = above) {
    background-color: @bg-color;
    color: @text-color;
  }

  // Unless background is transparent or white, set border color a little
  // darker than background color.
  & when not (@pane-style-border = false) and not (@bg-color = transparent) and not (@bg-color = white) {
    & when (@pane-style-title-position = inside) {
      border-color: darken(@bg-color, 10%);
    }
    > .pane-content when (@pane-style-title-position = above) {
      border-color: darken(@bg-color, 10%);
    }
  }

  // When perceived brightness of both bg color and text color is dark, use
  // lighter text and underline links.
  & when (luma(@bg-color) < 50%) and (alpha(@bg-color) > .5) and (luma(@text-color) < 50%) {
    & when (@pane-style-title-position = inside) {
      &, a:not(.btn), > .pane-title, h2, h3, h4, h5, h6, li:before, li:after {
        color: white;
      }
      a:not(.btn), > .pane-title a {
        text-decoration: underline;
        &:hover {
          text-decoration: none;
        }
      }
      > .pane-title when (@pane-style-title-bordered) {
        border-bottom-color: white !important;
      }
    }
    > .pane-content when (@pane-style-title-position = above) {
      &, a:not(.btn), h2, h3, h4, h5, h6 {
        color: white;
      }
      a:not(.btn) {
        text-decoration: underline;
        &:hover {
          text-decoration: none;
        }
      }
    }
  }

  // When perceived brightness of both bg color and text color is light, use
  // darker text and standard blue links.
  & when (luma(@bg-color) > 50%) and (luma(@text-color) > 50%) {
    & when (@pane-style-title-position = inside) {
      &, > .pane-title, h2, h3, h4, h5, h6 {
        color: black;
      }
      a:not(.btn) {
        color: #337ab7;
      }
    }
    > .pane-content when (@pane-style-title-position = above) {
      &, h2, h3, h4, h5, h6 {
        color: black;
      }
      a:not(.btn) {
        color: #337ab7;
      }
    }
  }
}

// Region and pane styles (when enabled).
& when (@pane-style-enabled = true) {

  // Generic pane titles.
  .pane-style-style:not(.pane-style-style-unstyled) > .pane-title {
    .pane-style-title-generic();
  }

  // Generic region and pane content styles.
  .region-style-style:not(.region-style-style-unstyled),
  .pane-style-style:not(.pane-style-style-unstyled) {
    & when (@pane-style-title-position = inside) {
      .pane-style-content-generic();
    }
    > .pane-content when (@pane-style-title-position = above) {
      .pane-style-content-generic();
    }
  }

  // Default style (when enabled).
  .pane-page-content-top when not (@pane-style-default-bg-color = false) {
    > .pane-title {
      .pane-style-title-generic();
    }
    &:not(.pane-style-style):not(.pane-style-style-unstyled) {
      & when (@pane-style-title-position = inside) {
        .pane-style-content-generic();
      }
      > .pane-content when (@pane-style-title-position = above) {
        .pane-style-content-generic();
      }
      .pane-style-variant(@pane-style-default-bg-color);
    }
  }

  // Info style.
  .region-style-style-info,
  .pane-style-style-info {
    .pane-style-variant(@pane-style-info-bg-color);
  }

  // Attention style.
  .region-style-style-attention,
  .pane-style-style-attention {
    .pane-style-variant(@pane-style-attention-bg-color);
  }

  // Subdued style.
  .region-style-style-subdued,
  .pane-style-style-subdued {
    .pane-style-variant(@pane-style-subdued-bg-color);
  }

  // Alternate style.
  .region-style-style-alternate,
  .pane-style-style-alternate {
    .pane-style-variant(@pane-style-alternate-bg-color);
  }

}

// Region styles (regardless of whether or not region and pane styles are
// enabled).
.region-style-style {
  // Remove top margin from first pane's title.
  & > .panel-pane:first-child > .pane-title,
  & > .panels-ipe-portlet-wrapper:first-child > .panels-ipe-portlet-content > .panel-pane > .pane-title {
    margin-top: 0;
  }

  // Remove bottom margin from last pane.
  & > .panel-pane:last-child,
  & > .panels-ipe-portlet-wrapper:last-child > .panels-ipe-portlet-content > .panel-pane {
    margin-bottom: 0;
  }
}
