// Psuedo-responsive reCAPTCHA.
.g-recaptcha > div {
  width: auto !important;
  height: auto !important;
}
.g-recaptcha > div > div {
  padding-bottom: 78px;
  height: 0;
  overflow: hidden;
}
