// Page site name.
.pane-page-site-name .pane-content {
  // Set font family.
  & when not (@site-name-font-family = false) {
    font-family: @site-name-font-family;
  }

  // It may be linked, but use the inherited color.
  a {
    color: inherit;
  }
}
