// More accurate tabledrag positioning.
form .field-multiple-table td.field-multiple-drag {
  a.tabledrag-handle {
    padding-left: 8px;
    padding-right: 0;
  }
  .tabledrag-changed {
    padding-left: 8px;
  }
}

// Remove margin from "add more" button.
form .field-add-more-submit {
  margin: 0;
}
