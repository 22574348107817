// Since a hidden field must come before the file input, we have to target file
// fields and add the left border radius. See bootstrap_file_widget().
.form-managed-file.input-group .form-file {
  border-radius: @input-border-radius 0 0 @input-border-radius !important;
  &.input-sm {
    border-radius: @input-border-radius-small 0 0 @input-border-radius-small !important;
  }
  &.input-lg {
    border-radius: @input-border-radius-large 0 0 @input-border-radius-large !important;
  }
}
