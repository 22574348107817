// Make the contextual trigger a little taller to remove the bottom border.
a.contextual-links-trigger {
  height: 19px;
}

// Force standard styling on contextual links.
div.contextual-links-wrapper a {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 13px;
  line-height: 1em;
  font-weight: normal;
  text-shadow: none;
  text-decoration: none !important;
}
