// Put remove button in top-right of Field Collection embed rows.
// Note: "remove-row" class added in steindom_theme_field_widget_form_alter().
.field-widget-field-collection-embed .field-multiple-table {
  td {
    position: relative;
  }
  button.remove-row {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 85px;
  }
}
