// Give an explicit color so it's readable inside styled regions and panes.
.panel {
  color: @accordion-text-color;
}
.panel-heading a {
  color: @accordion-heading-text-color !important;
  text-decoration: none !important;
}
.panel-body a {
  color: @link-color !important;
}

// Override title font.
.panel-title when not (@accordion-heading-font-family = false) {
  font-family: @accordion-heading-font-family;
}

// Give collapsible panel headings a background color and make the link
// full-width.
.panel--collapsible > .panel-heading {
  background-color: @accordion-heading-bg-color;
  padding: 0;
  a {
    display: block;
    padding: 10px 15px;
    text-decoration: none;
    &:hover {
      background-color: darken(@accordion-heading-bg-color, 5%);
    }
  }
}

// Add right-spacing to Glyphicons in panel titles.
.panel-title .glyphicon {
  margin-right: 5px;
}

// Toggle collapsed/expanded panel title icons.
.panel-title {
  a .glyphicon--collapsed {
    display: none;
  }
  a.collapsed .glyphicon--collapsed {
    display: inline-block;
  }
  a.collapsed .glyphicon--expanded {
    display: none;
  }
}
