// Modal backdrop color, to match jQuery UI dialog and CKEditor overlays.
div#modalBackdrop {
  background-color: black !important;
  opacity: .3 !important;
}

// Set a max-width on the modal.
#modal-content {
  max-width: 1000px;
}
