// Invisible, focusable "skip to main content" link.
#skip-link {
  text-align: center;
}

// Remove white space in "read more" links.
.more-link {
  white-space: nowrap;
}

// Required asterisks.
.form-required {
  color: red;
}
