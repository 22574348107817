// Login cog link.
.user-login {
  position: fixed;
  bottom: 15px;
  left: 15px;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  font-size: 20px;
  line-height: 20px;
  width: 44px;
  text-align: center;
  opacity: 0;
  transition: opacity 0.15s ease-out;
  &:hover {
    opacity: 1;
    color: white;
  }
}
