// Base theme styles.
@import '../../steindom_theme/less/base';

// Variables.
@import 'variables';

// Quick links.
.page-quick-links {
  font-size: 14px;
  line-height: 1.3em;
  text-align: center;

  .panel-pane, ul {
    margin: 0;
  }

  li {
    padding: 0;
  }

  .pane-content a {
    color: white;
    opacity: .7;
    padding: 10px;
    display: inline-block;
    &:hover, &:focus {
      text-decoration: none;
      background: rgba(255, 255, 255, 0.2);
    }
  }
}

// Header.
.page-header {
  background: @theme-header-footer-bg;
  color: white;
  padding: 30px 0;
  text-align: center;

  .panel-pane {
    margin: 0;
  }

  // Links.
  a {
    color: inherit;
    text-decoration: underline;
  }

  // Social media links.
  .pane-social-media-link-widget {
    margin-bottom: 20px;
  }

  // Site name.
  .pane-page-site-name .pane-content {
    line-height: 1.5em;
    font-size: 30px;
    @media @tablet {
      font-size: 50px;
    }
    a {
      text-decoration: none;
      transition: 300ms;
      &:hover {
        opacity: .5;
      }
    }
  }

  // Site slogan.
  .pane-page-slogan .pane-content {
    margin-top: 15px;
    line-height: 1.2em;
    font-weight: 300;
    opacity: .7;
    font-size: 20px;
    @media @tablet {
      font-size: 28px;
    }
  }
}

// Nav.
.page-nav {
  background: @main-menu-bg-color;

  .panel-pane {
    margin: 0;
  }

  @media @mobile-only {
    > .container {
      padding: 0;
    }
  }
}

// Page image.
.page-image {
  position: relative;
  min-height: 100px;

  .panel-pane {
    margin: 0;
  }

  // Image.
  .pane-page-image {
    picture, img {
      width: 100%;
    }
  }

  // Page title.
  .pane-page-title {
    background: rgba(0, 0, 0, .7);
    text-align: center;

    @media @tablet {
      body.with-page-image & {
        position: absolute;
        bottom: 0;
        width: 100%;
      }
    }

    h1 {
      margin: 0;
      color: white;
      font-size: 20px;
      padding: 20px;
      @media @tablet {
        font-size: 32px;
        padding: 40px;
      }
    }
  }
}

// Page content.
.page-content {
  background-color: @theme-content-bg-color;
  & when not (@theme-content-bg-image = false) {
    background-image: @theme-content-bg-image;
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  padding: 60px 0;

  // TODO Add something like this to the base theme.
  .pane-page-content-top {
    margin-bottom: 45px;
    .panel-pane {
      margin-bottom: 30px;
    }
    .views-row:not(.views-row-last) {
      margin-bottom: 20px;
    }
    .pane-entity-field {
      margin-bottom: 10px;
    }
  }
}

// Blog posts listing.
.view-blog-posts-listing {
  .views-field-field-blog-post-subtitle {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 80%;
    line-height:1.2em;
    margin-top: -2px;
  }
  .views-field-created {
    text-transform: uppercase;
    font-size: 80%;
    margin-top: -5px;
    opacity: 0.6;
  }
}

// Footer.
.page-footer {
  background: @theme-header-footer-bg;
  padding: 70px 0;
  text-align: center;
  font-size: 18px;

  &, .pane-title, a:not(.btn) {
    color: white;
  }

  .pane-title {
    font-size: 22px;
    margin-top: 0;
  }

  .pane-content {
    font-weight: 300;
  }

  a:not(.btn) {
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
}

// Credit.
.page-credit {
  font-weight: 300;
  font-size: 14px;
  font-style: italic;
  text-align: center;
  color: rgba(255, 255, 255, .5);
  line-height: 3em;

  .panel-pane {
    margin-bottom: 0;
  }

  a {
    text-decoration: underline;
    color: inherit;
    &:hover {
      text-decoration: none;
    }
  }
}

// Submenu.
.pane-submenu {
  .pane-title {
    font-size: 20px;
    line-height: 20px;
    margin-top: 0;
    margin-bottom: 0;
    padding: 10px 14px;
    text-transform: uppercase;
    background: @theme-submenu-title-bg-color;
    font-family: @theme-submenu-title-font-family;
    color: #FFF;
    text-align: center;
    border-bottom: none;
  }
  .pane-content {
    padding: 0.75em 0 !important;
    background: rgba(0, 0, 0, 0.04);
    border: 1px solid rgba(39, 84, 119, 0.3);
    .submenu-arrows();
    .submenu-font-size-tier();
    a {
      padding: 0.5em 0.4em;
      background: transparent;
    }
    > div > ul {
      padding-left:1.25em;
    }
    ul a:hover {
      background: @theme-submenu-title-bg-color;
      color: #FFF;
      text-decoration:none;
    }
  }
}

@body-bg:#12364d;@font-family-script:'Playfair Display', serif;@theme-header-footer-bg:darken(#12364d, 5%);
@body-bg: #12364d;@font-family-script: 'Playfair Display', serif;@theme-header-footer-bg: darken(#12364d, 5%);