// Fix overlay backgrounds. See it in action with the Linkit modal.
// Also match background color to Ctools and CKEditor overlays.
.ui-widget-overlay {
  background-repeat: repeat;
  background-color: black !important;
  opacity: .3 !important;
}

// Set jQuery UI dialog max-width slightly smaller than Ctools modal max-width.
.ui-dialog {
  max-width: 780px;
}

// Borders around autocomplete items. See it in action on an Autocomplete
// Deluxe field.
.ui-menu .ui-menu-item a {
  border: 1px solid #ccc;
}

// Put jQuery UI "front" elements just a bit higher, so they appear above the
// Ctools modal, CKEditor maximize plugin, etc.
.ui-front {
  z-index: 10001 !important;
}

// Style jQuery UI tabs like Bootstrap tabs.
.ui-tabs {
  .ui-tabs-nav {
    padding: 0;
    border: 0;
    border-bottom: 1px solid @nav-tabs-border-color;
    border-radius: 0;

    li {
      background: none;
      border: none;
      margin: 0 0 -1px;
      padding: 0 !important;

      a {
        margin-right: 2px;
        border: 1px solid transparent;
        border-radius: @border-radius-base @border-radius-base 0 0;
        &:hover {
          border-color: @nav-tabs-link-hover-border-color @nav-tabs-link-hover-border-color @nav-tabs-border-color;
        }
      }

      &.ui-tabs-active a {
        &,
        &:hover,
        &:focus {
          background-color: @nav-tabs-active-link-hover-bg;
          border: 1px solid @nav-tabs-active-link-hover-border-color;
          border-bottom-color: transparent;
          cursor: default;
        }
      }
    }
  }
}
