// Add margins so it matches other fields in addressfield form fields.
form div.name-block, form div.street-block, form div.locality-block {
  margin-bottom: 15px;
}

// Then remove padding at bottom of addressfield panel body.
#addressfield-wrapper .panel-body {
  padding-bottom: 0;
}

// Because in webform.less we give selects a width of 'inherit', and because
// inline addressfield items are floated, a select with a long <option> will
// expand beyond the container element. This fixes that.
.addressfield-container-inline .form-item {
  max-width: 100%;
}
