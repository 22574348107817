// Give element opacity when hovering over it. Uses backface-visibility to
// remove flicker (see http://stackoverflow.com/a/13020881).
.opacity-hover(@hover-opacity: .5; @initial-opacity: 1) {
  & when not (@initial-opacity = @hover-opacity) {
    opacity: @initial-opacity;
  }
  transition: opacity 200ms;
  backface-visibility: hidden;
  &:hover {
    opacity: @hover-opacity;
  }
}

// Fixes colors if the text would be hard to read on a background color.
//
// @bg = the background color to check
// @text = the text color to check
// @headings = if true, also change color of headings
// @underline = if true, also underline links
.fix-colors(@bg, @text, @headings: true, @underline: true) {
  // Perceived brightness of both colors is dark.
  & when (luma(@bg) < 50%) and (alpha(@bg) > .5) and (luma(@text) < 50%) {
    // Use lighter text.
    &, a:not(.btn), .btn-link {
      color: white;
    }

    // Also change color of headings.
    & when (@headings = true) {
      h1, h2, h3, h4, h5, h6 {
        color: white;
      }
    }

    // Underline links.
    a:not(.btn) when (@underline = true) {
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
  }

  // Perceived brightness of both colors is light.
  & when (luma(@bg) > 50%) and (luma(@text) > 50%) {
    // Use darker text.
    & {
      color: black;
    }

    // Use standard blue links.
    a:not(.btn) {
      color: #337ab7;
    }
  }
}

// Creates breadcrumbs with triangle arrows on Bootstrap button groups.
//
// HTML example. Note there are no variant classes (e.g., 'btn-default').
// <div class="btn-group">
//   <a href="#" class="btn">Home</a>
//   <a href="#" class="btn">Projects</a>
//   <span class="btn">Project 1</span>
// </div>
.triangle-breadcrumb(@font-size: @font-size-base, @arrow-width: 10px, @color: @btn-default-color, @bg-color: @btn-default-bg, @border-color: @btn-default-border) {
  font-size: @font-size;

  .btn {
    // Create new button variant.
    .button-variant(@color; @bg-color; @border-color);

    padding: 6px 12px 6px 24px;
    &:first-child {
      padding-left: 18px;
    }

    // Remove box shadow on buttons since it's hard to implement on triangles.
    &:active, &.active {
      box-shadow: none;
    }

    // Background triangle.
    &:after {
      content: " ";
      display: block;
      width: 0;
      height: 0;
      border-top: @font-size solid transparent;
      border-bottom: @font-size solid transparent;
      border-left: @arrow-width solid @bg-color;
      position: absolute;
      top: 50%;
      margin-top: (@font-size * -1);
      margin-left: -1px;
      left: 100%;
      z-index: 3;
    }
    &:focus:after, &.focus:after {
      border-left: @arrow-width solid darken(@bg-color, 10%);
    }
    &:hover:after {
      border-left: @arrow-width solid darken(@bg-color, 10%);
    }
    &:active, &.active {
      &:after {
        border-left: @arrow-width solid darken(@bg-color, 10%);
      }
      &:hover, &:focus, .focus {
        &:after {
          border-left: @arrow-width solid darken(@bg-color, 17%);
        }
      }
    }

    // Border triangle.
    &:before {
      content: " ";
      display: block;
      width: 0;
      height: 0;
      border-top: @font-size solid transparent;
      border-bottom: @font-size solid transparent;
      border-left: @arrow-width solid @border-color;
      position: absolute;
      top: 50%;
      margin-top: (@font-size * -1);
      left: 100%;
      z-index: 3;
    }
    &:focus:before, &.focus:before {
      border-left: @arrow-width solid darken(@border-color, 25%);
    }
    &:hover:before {
      border-left: @arrow-width solid darken(@border-color, 12%);
    }
    &:active, &.active {
      &:before {
        border-left: @arrow-width solid darken(@border-color, 10%);
      }
      &:hover, &:focus, .focus {
        &:before {
          border-left: @arrow-width solid darken(@border-color, 25%);
        }
      }
    }
  }
}
