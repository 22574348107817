// Link form fields.
.form-type-link-field {
  // Add margin between Link title & url fields.
  .link-field-column + .link-field-column {
    margin-left: 15px;
  }

  // Add margin above attributes wrapper.
  .link-attributes {
    margin-top: 5px;
  }
}
