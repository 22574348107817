// Modal backdrop color, to match Ctools and jQuery UI dialog overlays.
.cke_dialog_background_cover {
  background-color: black !important;
  opacity: .3 !important;
}

// Increase size of dropdowns.
.cke_combopanel {
  width: 400px !important;
  height: 400px !important;
}
