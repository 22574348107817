// Sticky nav.
.sticky-nav.affix {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;

  // Offset when coupled with Adminimal Menu.
  body.adminimal-menu & {
    top: 29px;
  }
}

// Show sticky nav placeholder on desktop only.
.sticky-nav-placeholder {
  display: none;
}
@media @desktop {
  .sticky-nav-placeholder {
    display: block;
  }
}
