// Position Colorbox over Panels IPE toolbar (99999).
#cboxOverlay {
  z-index: 100000;
}
#colorbox {
  z-index: 100001;
}

// Adjust positioning of caption text.
#cboxCurrent {
  font-size: 13px;
  line-height: 1em;
  bottom: 5px;
}
